/**
 * Created by xinsw on 16/9/9.
 *
 * 公用的service封装
 * 1、图片上传 uploadImageFile
 *
 */
import * as API from "../utils/http/api";


/**
 * 上传图片
 * @param file  Blob
 * @returns {Promise.<*>}
 */
export async function uploadImageFile(file) {
  try {
    let formData = new FormData();
    //这里一定要有file.name 否者上传的时候header默认以blob为name
    formData.append('imagefile', file, file.name);
    return await  API.upload('imageFileUpload', {formType: 'file', formData});
  } catch (error) {
    return error;
  }
}


/**
 * 根据城市获取天气信息
 * @param city 城市
 */
export async function getWeather(city) {
  /*return new Promise((resolve, reject) => {
   /*let url = 'http://www.pm25.in/api/querys/pm2_5.json?' +
   'city=zhuhai&token=5j1znBVAsnSf5xQyNQyq';*/
  /*  let cityStr = city.replace('市', '');
   let url = 'http://wthrcdn.etouch.cn/WeatherApi?city=' + cityStr;
   //url='http://mobile.weather.com.cn/data/air/101280601.html?_=1484530694787';
   Vue.http.get(url, {
   credentials: false
   }).then(function (response) {
   // let stus = response.blob().getElementsByTagName("pm25");
   //console.log(stus);
   let xdom = String2XML(response.body);
   let pm25List = xdom.getElementsByTagName("pm25");
   if (pm25List.length > 0) {
   resolve(pm25List[0].childNodes[0].nodeValue)
   } else {
   resolve(0);
   }
   }, function (response) {
   reject(response.status);
   });
   })*/

  try {
    // 注意这里的await语句，其所在的函数必须有async关键字声明
    const url = 'http://wthrcdn.etouch.cn/WeatherApi';
    return await  API.get(url, {city}, {withCredentials: false});
  } catch (error) {
    return error;
  }
}

// 获取所有省份
export async function getProvinces() {
  try {
    return await  API.get('/address/province');
  } catch (error) {
    return error;
  }
}

// 根据省份获取城市
export async function getCitesByProvince(provinceId) {
  try {
    return await  API.get('address/city/byProvinceId', {provinceId});
  } catch (error) {
    return error;
  }
}

// 根据城市获取地区
export async function getDistrictsByCity(cityId) {
  try {
    return await  API.get('address/district/byCityId',{cityId});
  } catch (error) {
    return error;
  }
}
